import React, { FC } from "react";
import { Avatar, Box, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PromoterSummaryResponse } from "../../rest.client/usePromotersApi";
import { toPromoter } from "../../navPaths";

const PromoterLinkAvatarButton: FC<{ promoter: PromoterSummaryResponse }> = ({ promoter }) => {
   const nav = useNavigate();
   const theme = useTheme();

   return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
         {promoter.logoUrl && (
            <Avatar sx={{ width: 32, height: 32 }} alt={promoter.name + " logo"} src={promoter.logoUrl} />
         )}
         <Button size={"small"} variant={"text"} onClick={() => nav(toPromoter(promoter.id))}>
            {promoter.name}
         </Button>
      </Box>
   );
};

export default PromoterLinkAvatarButton;
