import React from "react";
import { Box, Card, CardMedia, List, ListItem, ListItemText, Paper, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RemoteSeries } from "../useRemoteSeries";
import PromoterLinkAvatarButton from "../../promoters/listPromotersPage/PromoterLinkAvatarButton";

export default function SeriesDetailsCard({ remoteSeries }: { remoteSeries: RemoteSeries }) {
   const series = remoteSeries.fullSeriesData;
   const itemStyle = { width: "120px" };
   const nav = useNavigate();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   return (
      <>
         {series && (
            <Card>
               <Paper sx={{ position: "relative" }}>
                  <CardMedia
                     component="img"
                     height="300"
                     image={series?.logoUrl != null ? series.logoUrl : "/no_banner.png"}
                     alt={series?.name}
                  />
                  <Box sx={{ position: "absolute", bottom: 0, backgroundColor: "rgb(.6,.6,.6,.6)", width: "100%" }}>
                     <Typography variant={isMobileDevice ? "h6" : "h2"}>{series.name}</Typography>
                  </Box>
               </Paper>
               <Paper sx={{ padding: "0px" }}>
                  <Paper elevation={2}>
                     <List sx={{ display: "flex", flexWrap: "wrap" }}>
                        {series.promoter && (
                           <ListItem sx={itemStyle}>
                              <ListItemText primary={<PromoterLinkAvatarButton promoter={series.promoter} />} />
                           </ListItem>
                        )}
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={series.matchWinPoints} secondary={"Win points"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={series.matchDrawPoints} secondary={"Draw points"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={series.matchLossPoints} secondary={"Loss points"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={series.byePoints} secondary={"Bye points"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={series.participationPoints} secondary={"Participation points"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={series.status} secondary={"Status"} />
                        </ListItem>
                     </List>
                  </Paper>
               </Paper>
            </Card>
         )}
      </>
   );
}
