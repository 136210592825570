import React, { useEffect, useMemo, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Paper,
   TablePagination,
   TextField,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomIconButton from "../../common/CustomIconButton";
import { Maybe } from "../../TYPE";
import HelpIcon from "../../common/HelpIcon";
import LinkIcon from "@mui/icons-material/Link";
import { TournamentSummary, useTournamentApi } from "../../rest.client/useTournamentApi";
import { TbTournament } from "react-icons/tb";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import TournamentTile from "../../pageTournament/listPage/TournamentTile";
import TablePaginationActions from "../../gameFeatures/myGamesPage/TablePagingActions";
import { RemoteSeries } from "../useRemoteSeries";
import { RemotePromoter } from "../../promoters/useRemotePromoter";
import TournamentSelectorAutocomplete from "../../promoters/TournamentSelectorAutocomplete";
import useCurrentUser from "../../auth/useCurrentUser";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import BackDropSpinner from "../../common/BackDropSpinner";

export default function SeriesTournamentsExpander({
   remotePromoter,
   remoteSeries,
}: {
   remotePromoter: RemotePromoter;
   remoteSeries: RemoteSeries;
}) {
   const theme = useTheme();
   const currentUser = useCurrentUser();
   const response = remoteSeries.fullSeriesData;
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(25);
   const [searchText, setSearchText] = useState<Maybe<string>>(null);
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const {
      findTournamentsBySeriesId: { call: findTournaments, responseData: tournamentFindResponse },
      findTournamentsEligibleForSeriesLink: {
         call: findTournamentsEligibleForSeriesLink,
         responseData: eligibleTournamentsResponse,
      },
      loading,
   } = useTournamentApi();
   const tournaments = useMemo(
      () => tournamentFindResponse?._embedded.tournaments || [],
      [tournamentFindResponse?._embedded.tournaments]
   );
   const pageData = tournamentFindResponse?.page;
   const messageItem = useAddMessageItem();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [selectedTournament, setSelectedTournament] = useState<Maybe<TournamentSummary>>();

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      if (response) {
         findTournaments({
            queryParams: {
               seriesId: response.id,
               searchText: searchText,
               size: rowsPerPage,
               page,
               sort: "createDate,desc",
               projection: "summary",
            },
         });
      }
   }, [findTournaments, page, response, rowsPerPage, searchText]);

   const tournamentTiles = useMemo(() => {
      if (tournaments.length > 0) {
         return tournaments.map((tournamentSummary) => (
            <TournamentTile
               key={tournamentSummary.id}
               tournamentSummary={tournamentSummary}
               elevation={2}
               buttonBar={
                  response?.isAdmin ? (
                     <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <CustomIconButton
                           icon={<LinkOffIcon />}
                           onClick={async (e) => {
                              e.stopPropagation();
                              await remoteSeries.unlinkTournament(tournamentSummary.id);
                              messageItem("Tournament unlinked!", "success");
                           }}
                        >
                           Unlink
                        </CustomIconButton>
                     </Paper>
                  ) : null
               }
            />
         ));
      } else {
         return (
            <Alert severity={"info"} sx={{ width: "100%" }}>
               {"No tournaments match your filters!"}
            </Alert>
         );
      }
   }, [messageItem, remoteSeries, response?.isAdmin, tournaments]);

   return (
      <Accordion defaultExpanded={true} sx={{ width: "100%" }}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
               }}
            >
               <TbTournament size={"24px"} />
               <Typography>Tournaments</Typography>
               {response?.isAdmin && (
                  <HelpIcon
                     message={
                        "This lists all tournaments that are currently linked to this series. You can link up to 100 tournaments per series."
                     }
                  />
               )}
            </Box>
         </AccordionSummary>
         <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
            <BackDropSpinner show={loading} />
            {response?.isAdmin && (
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     gap: theme.spacing(1),
                     width: smallScreen ? "300px" : "375px",
                  }}
               >
                  <TournamentSelectorAutocomplete
                     tournamentSummary={selectedTournament}
                     selectionHandler={setSelectedTournament}
                     values={eligibleTournamentsResponse?._embedded.tournaments || []}
                     searchHandler={(value) =>
                        findTournamentsEligibleForSeriesLink({
                           queryParams: {
                              seriesId: response.id || "",
                              page: 0,
                              size: 25,
                              projection: "summary",
                              sort: "createDate,desc",
                              searchText: value,
                              userId: currentUser.user?.uid || "",
                           },
                        })
                     }
                  />
                  <CustomIconButton
                     icon={<LinkIcon />}
                     buttonProps={{ disabled: selectedTournament == null }}
                     onClick={async () => {
                        if (selectedTournament) {
                           await remoteSeries.linkTournament(selectedTournament?.id);
                           setSelectedTournament(null);
                           messageItem("Tournament linked successfully.", "success");
                        }
                     }}
                  >
                     Link
                  </CustomIconButton>
               </Box>
            )}
            <Box
               sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
               }}
            >
               <TextField
                  size={"small"}
                  variant={"outlined"}
                  defaultValue={""}
                  label={"Search"}
                  sx={{ minWidth: "200px", flexGrow: 1 }}
                  onChange={(ev) => {
                     if (searchActiveTimeout != null) {
                        clearTimeout(searchActiveTimeout);
                        setSearchActiveTimeout(null);
                     }
                     const timeOutId = window.setTimeout(() => {
                        setSearchText(ev.target.value);
                     }, 500);
                     setSearchActiveTimeout(timeOutId);
                  }}
               />
               {pageData && (
                  <Box>
                     <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={pageData.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                           inputProps: {
                              "aria-label": "rows per page",
                           },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                     />
                  </Box>
               )}
            </Box>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                  flexWrap: "wrap",
               }}
            >
               {tournamentTiles}
            </Box>
         </AccordionDetails>
      </Accordion>
   );
}
