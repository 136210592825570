import React, { FC, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material";
import BackDropSpinner from "../../common/BackDropSpinner";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import { useParams } from "react-router-dom";
import useReload from "../../header/useReload";
import { SERIES_KEY } from "../../common/reloadKeys";
import { SxProps } from "@mui/system";
import useRemoteSeries from "../useRemoteSeries";
import useRemotePromoter from "../../promoters/useRemotePromoter";
import SeriesDetails from "./SeriesDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import SeriesStandingsTableExpander from "./SeriesStandingsTableExpander";
import SeriesTournamentsExpander from "./SeriesTournamentsExpander";
import SeriesPointsTierExpander from "./SeriesPointsTierExpander";

const contentRootSx: SxProps = {
   width: "100%",
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-start",
   alignItems: "center",
};

const SeriesViewPage: FC = () => {
   const { seriesId } = useParams();
   const remoteSeries = useRemoteSeries(seriesId);
   const remotePromoter = useRemotePromoter(remoteSeries.fullSeriesData?.promoter.id);
   const { reload } = useReload(SERIES_KEY);
   const theme = useTheme();

   useEffect(() => {
      if (remoteSeries.fullSeriesData == null || reload) {
         remoteSeries.loadData();
      }
   }, [reload, remoteSeries]);

   useEffect(() => {
      if (remotePromoter.promoterFullResponse == null) {
         remotePromoter.loadData();
      }
   }, [remotePromoter]);

   return (
      <ScrollableBorderedWrapper>
         <BackDropSpinner show={remoteSeries.loading} />
         <Box sx={contentRootSx}>
            <Box sx={{ width: "100%" }}>
               <Accordion defaultExpanded={true} sx={{ width: "100%" }}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                     <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                        <InfoIcon />
                        <Typography>Series details</Typography>
                     </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                     <SeriesDetails remotePromoter={remotePromoter} remoteSeries={remoteSeries} />
                  </AccordionDetails>
               </Accordion>

               <SeriesStandingsTableExpander remoteSeries={remoteSeries} />
               {remotePromoter.promoterFullResponse?.isAdmin && (
                  <SeriesPointsTierExpander remoteSeries={remoteSeries} />
               )}
               <SeriesTournamentsExpander remotePromoter={remotePromoter} remoteSeries={remoteSeries} />
            </Box>
         </Box>
      </ScrollableBorderedWrapper>
   );
};

export default SeriesViewPage;
