import React, { useState } from "react";
import { Box, Button, TableCell, TableRow, TextField, useMediaQuery, useTheme } from "@mui/material";
import { RemoteSeries } from "../useRemoteSeries";
import CustomIconButton from "../../common/CustomIconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import { SeriesPointsTierSummaryResponse } from "../../rest.client/useSeriesApi";
import OkCancelDialog from "../../OkCancelDialog";
import EditIcon from "@mui/icons-material/Edit";
import useRemoteSeriesPointsTier from "../useRemoteSeriesPointsTier";
import LinkIcon from "@mui/icons-material/Link";
import { Consumer } from "../../utils";

export default function SeriesPointsTierRow({
   remoteSeries,
   tierResponse,
   showDelete,
   onClickLink,
}: {
   remoteSeries: RemoteSeries;
   tierResponse: SeriesPointsTierSummaryResponse;
   showDelete: boolean;
   onClickLink: Consumer<SeriesPointsTierSummaryResponse>;
}) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const remotePointsTier = useRemoteSeriesPointsTier(tierResponse);
   const displayMessage = useAddMessageItem();
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [editMode, setEditMode] = useState<boolean>(false);

   return (
      <TableRow key={tierResponse.tier} sx={{ cursor: "pointer" }}>
         {!editMode && (
            <>
               <TableCell>{tierResponse.tier}</TableCell>
               <TableCell>{tierResponse.name}</TableCell>
               <TableCell>{tierResponse.points}</TableCell>
               <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <CustomIconButton
                        icon={<EditIcon />}
                        onClick={async () => {
                           setEditMode(true);
                        }}
                     >
                        Edit
                     </CustomIconButton>
                     <CustomIconButton
                        icon={<LinkIcon />}
                        onClick={async () => {
                           onClickLink(tierResponse);
                        }}
                     >
                        Link
                     </CustomIconButton>
                     {showDelete && (
                        <CustomIconButton
                           icon={<DeleteForeverIcon />}
                           onClick={async () => {
                              setShowDeleteDialog(true);
                           }}
                        >
                           Delete
                        </CustomIconButton>
                     )}
                  </Box>
               </TableCell>
            </>
         )}
         {editMode && (
            <>
               <TableCell colSpan={4}>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: smallScreen ? "column" : "row",
                        gap: theme.spacing(1),
                     }}
                  >
                     <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                        <TextField
                           fullWidth
                           required
                           size={"small"}
                           variant={"outlined"}
                           label={"Tier name"}
                           onChange={(ev) => remotePointsTier.setName(ev.target.value)}
                           value={remotePointsTier.name}
                           inputProps={{ maxLength: 100 }}
                        />
                        <TextField
                           fullWidth
                           required
                           size={"small"}
                           variant={"outlined"}
                           type={"number"}
                           label={"Points"}
                           error={isNaN(remotePointsTier.points) || remotePointsTier.points < 0}
                           onChange={(ev) => {
                              remotePointsTier.setPoints(parseInt(ev.target.value));
                           }}
                           value={remotePointsTier.points}
                           inputProps={{ maxLength: 100 }}
                        />
                     </Box>
                     <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                        <Button
                           variant={"contained"}
                           disabled={
                              remotePointsTier.name === "" ||
                              remotePointsTier.points == null ||
                              isNaN(remotePointsTier.points) ||
                              remotePointsTier.points < 0
                           }
                           onClick={async () => {
                              await remotePointsTier.update();
                              await remoteSeries.loadData();
                              displayMessage("Tier " + remotePointsTier.name + " update!", "success");
                              setEditMode(false);
                           }}
                        >
                           Save
                        </Button>
                        <Button
                           color="secondary"
                           onClick={async () => {
                              setEditMode(false);
                           }}
                        >
                           Cancel
                        </Button>
                     </Box>
                  </Box>
               </TableCell>
            </>
         )}

         <OkCancelDialog
            open={showDeleteDialog}
            onCancel={() => setShowDeleteDialog(false)}
            onOk={async () => {
               await remoteSeries.deletePointsTier(tierResponse.tier);
               displayMessage("Tier deleted.", "success");
            }}
            title={`Delete points tier "${tierResponse.name}"`}
            message={`Are you sure you want to delete ${tierResponse.name}`}
         />
      </TableRow>
   );
}
