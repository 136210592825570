import React from "react";
import { Box, Paper, useTheme } from "@mui/material";
import CustomIconButton from "../../common/CustomIconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Procedure } from "../../utils";
import { useFilePicker } from "use-file-picker";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { RemoteSeries } from "../useRemoteSeries";
import { RemotePromoter } from "../../promoters/useRemotePromoter";

export default function SeriesControls({
   remoteSeries,
   onClickEdit,
   remotePromoter,
}: {
   remoteSeries: RemoteSeries;
   remotePromoter: RemotePromoter;
   onClickEdit: Procedure;
}) {
   const theme = useTheme();
   const messages = useAddMessageItem();

   const { openFilePicker } = useFilePicker({
      readAs: "ArrayBuffer",
      accept: "image/*",
      multiple: false,
      onFilesSuccessfullySelected: async ({ plainFiles }) => {
         const formData = new FormData();
         formData.append("file", plainFiles[0]);
         await remoteSeries.uploadLogo(formData);
         messages("Logo updated!", "success");
      },
   });

   return (
      <Paper sx={{ width: "100%", display: "flex", justifyContent: "center" }} elevation={2}>
         {remotePromoter.promoterFullResponse?.isAdmin && (
            <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
               <CustomIconButton icon={<EditIcon />} buttonProps={{ variant: "text" }} onClick={onClickEdit}>
                  Edit
               </CustomIconButton>
               <CustomIconButton icon={<FileUploadIcon />} buttonProps={{ variant: "text" }} onClick={openFilePicker}>
                  Upload logo
               </CustomIconButton>
            </Box>
         )}
      </Paper>
   );
}
