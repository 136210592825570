import React, { useMemo } from "react";
import useCurrentUser from "../auth/useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { ALL_TOURNAMENTS_PATH, LOG_GAME_PATH, MY_GAMES_PATH, TUTORIALS_PATH } from "../index";
import { toMetaInsights, toMyTournaments, toPromoters, toPromotersInfo, toSeriesListPage } from "../navPaths";
import { Box, Link, Tab, Tabs, useTheme } from "@mui/material";
import MyGamesTabLabel from "./MyGamesTabLabel";
import { FaDiscord } from "react-icons/fa";
import useGlobal from "../global/useGlobal";
import { Maybe, TPoint2 } from "../TYPE";
import { randomPos } from "../utils";

enum TabValues {
   NONE = 0,
   BRAINSTORM,
   PROMOTERS,
   SERIES,
   INSIGHTS,
   MY_TOURNAMENTS,
   MY_GAMES,
   LOG_GAME,
   TUTORIALS,
   DISCORD,
}

const HeaderNavigator: React.FC = () => {
   const user = useCurrentUser();
   const nav = useNavigate();
   const theme = useTheme();
   const location = useLocation();
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);

   const path = location.pathname;

   const selected: TabValues = useMemo(() => {
      return path.includes(toPromoters())
         ? TabValues.PROMOTERS
         : path.includes(toSeriesListPage())
         ? TabValues.SERIES
         : path.includes("/insights/")
         ? TabValues.INSIGHTS
         : path === "/" || path.includes(ALL_TOURNAMENTS_PATH)
         ? TabValues.BRAINSTORM
         : path.includes(MY_GAMES_PATH)
         ? TabValues.MY_GAMES
         : path.includes(LOG_GAME_PATH)
         ? TabValues.LOG_GAME
         : path.includes(toMyTournaments())
         ? TabValues.MY_TOURNAMENTS
         : path.includes(TUTORIALS_PATH)
         ? TabValues.TUTORIALS
         : TabValues.NONE;
   }, [path]);

   return (
      <Box>
         <Tabs value={selected} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab
               value={TabValues.BRAINSTORM}
               onClick={() => {
                  nav("/");
                  setTilesPos(randomPos());
               }}
               label={"Tournaments"}
            />
            <Tab
               value={TabValues.SERIES}
               onClick={() => {
                  nav(toSeriesListPage());
                  setTilesPos(randomPos());
               }}
               label={"Series"}
            />
            <Tab
               value={TabValues.PROMOTERS}
               onClick={() => {
                  nav(toPromotersInfo());
                  setTilesPos(randomPos());
               }}
               label={"Promoters"}
            />
            <Tab
               value={TabValues.TUTORIALS}
               onClick={() => {
                  nav(TUTORIALS_PATH);
                  setTilesPos(randomPos());
               }}
               label={"Tutorials"}
            />
            <Tab
               value={TabValues.INSIGHTS}
               onClick={() => {
                  nav(toMetaInsights());
                  setTilesPos(randomPos());
               }}
               label={"Insights"}
            />
            {user.loggedIn && (
               <Tab
                  value={TabValues.MY_TOURNAMENTS}
                  onClick={() => {
                     nav(toMyTournaments());
                     setTilesPos(randomPos());
                  }}
                  label={"My tournaments"}
               />
            )}
            {user.loggedIn && (
               <Tab
                  value={TabValues.MY_GAMES}
                  onClick={() => {
                     nav(MY_GAMES_PATH);
                     setTilesPos(randomPos());
                  }}
                  label={<MyGamesTabLabel />}
               />
            )}
            {user.loggedIn && (
               <Tab
                  value={TabValues.LOG_GAME}
                  onClick={() => {
                     nav(LOG_GAME_PATH);
                     setTilesPos(randomPos());
                  }}
                  label={"Log personal game"}
               />
            )}

            <Tab
               value={TabValues.DISCORD}
               label={
                  <Link target={"_blank"} rel="noopener" href={"https://discord.gg/DAQ6SJaa6M"}>
                     <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                        <FaDiscord />
                        Discord
                     </Box>
                  </Link>
               }
            />
         </Tabs>
      </Box>
   );
};

export default HeaderNavigator;
