import {
   SaveTournamentRequest,
   TournamentFull,
   TournamentSummary,
   useTournamentApi,
} from "../rest.client/useTournamentApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import useCurrentUser from "../auth/useCurrentUser";
import { Maybe } from "../TYPE";
import { Consumer, Procedure, Supplier } from "../utils";
import { TagSummary } from "../rest.client/useTagsApi";

export interface RemoteTournament {
   canEdit: boolean;
   hasEnded: boolean;
   tournament: Maybe<TournamentFull>;
   start: Supplier<Promise<unknown>>;
   end: Supplier<Promise<unknown>>;
   uploadLogo: (image: FormData) => Promise<unknown>;
   saveRequest: Maybe<SaveTournamentRequest>;
   setSaveRequest: Consumer<SaveTournamentRequest>;
   saveTournament: () => Promise<TournamentSummary>;
   loadData: Procedure;
   loadTags: Procedure;
   tags?: TagSummary[];
   userIsRegistered: boolean;
   canClose: boolean;
   isOrganiser: boolean;
   isCreator: boolean;
   loading: boolean;
   canEditNotEnded: boolean;
   id?: string;
}

export default function useRemoteTournament(id?: string): RemoteTournament {
   const user = useCurrentUser();
   const [saveRequest, setSaveRequest] = useState<Maybe<SaveTournamentRequest>>(null);

   const {
      getTournamentFullById: { call: getTournamentFullById, responseData: tournament },
      startTournament: { call: startTournament },
      endTournament: { call: endTournament },
      uploadLogo: { call: uploadLogoCall },
      getTagsInTournament: { call: getTags, responseData: tags },
      updateTournament: { call: updateCall },
      createTournament: { call: createTournament },
      loading,
   } = useTournamentApi();

   const isOrganiser = tournament != null && tournament.isOrganiser;
   const isCreator = tournament != null && tournament.creator.id === user.user?.uid;
   const canEdit = isOrganiser;
   const ended = tournament?.status === "ENDED";
   const canEditNotEnded = canEdit && !ended;
   const userIsRegistered = Boolean(tournament?.userRegistrationAccepted);
   const canClose =
      tournament != null &&
      tournament.stages.every((s) => s.status === "CLOSED") &&
      tournament.status === "STARTED" &&
      tournament.prizeTiers.length > 0;

   const loadData = useCallback(async () => {
      if (id) {
         const response = await getTournamentFullById({ pathParams: { id: id } });
         setSaveRequest({
            description: response.description,
            autoAcceptRegistrations: response.autoAcceptRegistrations,
            startDate: new Date(response.startDate),
            endDate: response.endDate != null ? new Date(response.endDate) : new Date(response.startDate),
            maxPlayerCount: response.maxPlayerCount,
            makeDecklistsVisible: response.areDecklistsVisible,
            sendRegistrationEmailNotifications: response.sendRegistrationEmailNotifications,
            registrationMode: response.registrationMode,
            isPublic: response.isPublic,
            location: response.location,
            name: response.name,
            tournamentOrganisers: response.tournamentOrganisers.map((o) => o.id),
         });
      }
   }, [getTournamentFullById, id]);

   const saveTournament = useCallback(async () => {
      if (saveRequest && id) {
         return await updateCall({ body: saveRequest, pathParams: { id: id } });
      } else if (saveRequest) {
         return await createTournament({ body: saveRequest });
      }
      throw new Error("Cannot save, data not set");
   }, [saveRequest, id, updateCall, createTournament]);

   const loadTags = useCallback(async () => {
      if (id) {
         await getTags({ pathParams: { id: id } });
      }
   }, [getTags, id]);

   useEffect(() => {
      loadData();
   }, [loadData]);

   const start = useCallback(() => {
      if (id != null) {
         return startTournament({ pathParams: { id: id } }).finally(() => loadData());
      }
      return Promise.reject("No id");
   }, [id, loadData, startTournament]);

   const end = useCallback(async () => {
      if (id != null) {
         try {
            return await endTournament({ pathParams: { id: id } });
         } finally {
            await loadData();
         }
      }
      return Promise.reject("No id");
   }, [endTournament, id, loadData]);

   const uploadLogo = useCallback(
      async (image: FormData) => {
         if (tournament) {
            return await uploadLogoCall({ pathParams: { id: tournament?.id }, body: image });
         }
      },
      [tournament, uploadLogoCall]
   );

   return useMemo(
      () => ({
         userIsRegistered,
         tournament,
         canEditNotEnded,
         isCreator,
         loadTags,
         tags: tags?._embedded.tags,
         hasEnded: ended,
         uploadLogo,
         saveTournament,
         saveRequest: saveRequest,
         setSaveRequest: setSaveRequest,
         loading,
         end,
         canClose,
         isOrganiser,
         canEdit,
         start,
         loadData,
         id,
      }),
      [
         userIsRegistered,
         tournament,
         canEditNotEnded,
         isCreator,
         loadTags,
         tags?._embedded.tags,
         ended,
         uploadLogo,
         saveTournament,
         saveRequest,
         loading,
         end,
         canClose,
         isOrganiser,
         canEdit,
         start,
         loadData,
         id,
      ]
   );
}
