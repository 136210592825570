import { useCallback, useMemo, useState } from "react";
import {
   SeriesPointsTierFullResponse,
   SeriesPointsTierSummaryResponse,
   useSeriesApi,
} from "../rest.client/useSeriesApi";
import { Maybe } from "../TYPE";

export interface RemoteSeriesPointsTier {
   dataInError: boolean;
   loadData: () => Promise<void>;
   setName: (name: string) => void;
   setPoints: (points: number) => void;
   update: () => Promise<void>;
   tierFullData: Maybe<SeriesPointsTierFullResponse>;
   linkPrizeTier: (tournamentId: string, tier: number) => Promise<void>;
   unlinkPrizeTier: (tournamentId: string, tier: number) => Promise<void>;
   loading: boolean;
   name: string;
   points: number;
}

export default function useRemoteSeriesPointsTier(tierData: SeriesPointsTierSummaryResponse): RemoteSeriesPointsTier {
   const {
      loading,
      getSeriesPointsTierFull: { call: getSeriesPointsTierFullCall, responseData: tierFullData },
      updateSeriesPointsTier: { call: updatePointsTierCall },
      linkSeriesPointsTier: { call: linkPointsTierCall },
      unlinkSeriesPointsTier: { call: unlinkPointsTierCall },
   } = useSeriesApi();
   const [name, setName] = useState<string>(tierData.name);
   const [points, setPoints] = useState<number>(tierData.points);

   const dataInError = name === "" || name == null || points < 0 || isNaN(points);

   const loadData = useCallback(async () => {
      const fullResponse = await getSeriesPointsTierFullCall({
         pathParams: {
            seriesId: tierData.seriesId,
            tierNumber: tierData.tier,
         },
      });
      setName(fullResponse.name);
      setPoints(fullResponse.points);
   }, [getSeriesPointsTierFullCall, tierData.seriesId, tierData.tier]);

   const update = useCallback(async () => {
      await updatePointsTierCall({
         pathParams: { tierNumber: tierData.tier, id: tierData.seriesId },
         body: { points: points, name: name },
      });
      await loadData();
   }, [loadData, name, points, tierData.seriesId, tierData.tier, updatePointsTierCall]);

   const linkPrizeTier = useCallback(
      async (tournamentId: string, tier: number) => {
         await linkPointsTierCall({
            pathParams: { tierNumber: tierData.tier, id: tierData.seriesId },
            body: { tournamentId, prizeTier: tier },
         });
         await loadData();
      },
      [linkPointsTierCall, loadData, tierData.seriesId, tierData.tier]
   );

   const unlinkPrizeTier = useCallback(
      async (tournamentId: string, tier: number) => {
         await unlinkPointsTierCall({
            pathParams: { tierNumber: tierData.tier, seriesId: tierData.seriesId, tournamentId, prizeTier: tier },
         });
         await loadData();
      },
      [loadData, tierData.seriesId, tierData.tier, unlinkPointsTierCall]
   );

   return useMemo(
      () => ({
         dataInError,
         loadData,
         setPoints,
         setName,
         unlinkPrizeTier,
         tierFullData,
         linkPrizeTier,
         update,
         loading,
         name,
         points,
      }),

      [dataInError, linkPrizeTier, loadData, loading, name, points, tierFullData, unlinkPrizeTier, update]
   );
}
