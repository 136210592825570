import React, { useEffect, useState } from "react";
import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   InputLabel,
   MenuItem,
   Paper,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../utils";
import { useNavigate } from "react-router-dom";
import { RemoteSeries } from "../useRemoteSeries";
import { SeriesPointsTierSummaryResponse } from "../../rest.client/useSeriesApi";
import useRemoteSeriesPointsTier from "../useRemoteSeriesPointsTier";
import TournamentSelectorAutocomplete from "../../promoters/TournamentSelectorAutocomplete";
import { Maybe } from "../../TYPE";
import { PrizeTierSummaryResponse, TournamentSummary, useTournamentApi } from "../../rest.client/useTournamentApi";
import LinkIcon from "@mui/icons-material/Link";
import CustomIconButton from "../../common/CustomIconButton";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import BackDropSpinner from "../../common/BackDropSpinner";
import FormControl from "@mui/material/FormControl";
import LinkOffIcon from "@mui/icons-material/LinkOff";

export default function SeriesPointsTierLinkDialog({
   open,
   close,
   remoteSeries,
   seriesTier,
}: {
   open: boolean;
   close: Procedure;
   remoteSeries: RemoteSeries;
   seriesTier: SeriesPointsTierSummaryResponse;
}) {
   const theme = useTheme();
   const showMessage = useAddMessageItem();
   const {
      findTournamentsBySeriesId: { call: findTournaments, responseData: tournamentFindResponse },
      getTournamentFullById: { call: getFullTournament, responseData: fullTournamentData },
      loading,
   } = useTournamentApi();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const nav = useNavigate();
   const [selectedTournament, setSelectedTournament] = useState<Maybe<TournamentSummary>>();
   const [selectedPrizeTier, setSelectedPrizeTier] = useState<Maybe<PrizeTierSummaryResponse>>();
   const remotePointsTier = useRemoteSeriesPointsTier(seriesTier);
   const mappings = remotePointsTier.tierFullData?.seriesPointsTierMappings || [];
   const prizeTiers = fullTournamentData?.prizeTiers || [];
   const anyLoading = loading || remotePointsTier.loading;

   useEffect(() => {
      if (open && remotePointsTier.tierFullData == null) {
         remotePointsTier.loadData();
      }
   }, [open, remotePointsTier]);

   useEffect(() => {
      if (selectedTournament) {
         getFullTournament({ pathParams: { id: selectedTournament.id } });
      }
   }, [getFullTournament, selectedTournament]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         fullWidth={true}
         maxWidth={"lg"}
         fullScreen={smallScreen}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">"{remotePointsTier.tierFullData?.name}" result links</DialogTitle>
         <DialogContent sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
            <BackDropSpinner show={anyLoading} />
            <Box
               sx={{
                  marginTop: theme.spacing(1),
                  display: "flex",
                  flexDirection: smallScreen ? "column" : "row",
                  gap: theme.spacing(1),
                  width: "100%",
               }}
            >
               <Box sx={{ flexGrow: 1 }}>
                  <TournamentSelectorAutocomplete
                     tournamentSummary={selectedTournament}
                     selectionHandler={(selection) => {
                        setSelectedTournament(selection);
                        setSelectedPrizeTier(null);
                     }}
                     values={tournamentFindResponse?._embedded.tournaments || []}
                     searchHandler={(value) =>
                        findTournaments({
                           queryParams: {
                              seriesId: remoteSeries.seriesData.id || "",
                              page: 0,
                              size: 25,
                              projection: "summary",
                              sort: "createDate,desc",
                              searchText: value,
                           },
                        })
                     }
                  />
               </Box>
               <Box sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth={true}>
                     <InputLabel id="test-select-label">Select result tier</InputLabel>
                     <Select
                        labelId="test-select-label"
                        disabled={selectedTournament == null}
                        value={selectedPrizeTier?.tier || -1}
                        fullWidth={true}
                        size={"small"}
                        onChange={(value) => {
                           setSelectedPrizeTier(prizeTiers.find((tier) => tier.tier === value.target.value));
                        }}
                        label="Select result tier"
                     >
                        {prizeTiers.map((pT) => (
                           <MenuItem value={pT.tier}>{pT.name}</MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </Box>
               <CustomIconButton
                  icon={<LinkIcon />}
                  sx={{ maxWidth: "140px" }}
                  buttonProps={{
                     variant: "contained",
                     disabled: selectedTournament == null || selectedPrizeTier == null,
                  }}
                  onClick={async () => {
                     if (selectedTournament && selectedPrizeTier) {
                        await remotePointsTier.linkPrizeTier(selectedTournament?.id, selectedPrizeTier?.tier);
                        showMessage("Points tier and tournament result linked!", "success");
                     }
                  }}
               >
                  Create link
               </CustomIconButton>
            </Box>
            <TableContainer component={Paper}>
               <Table sx={{}} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Tournament name</TableCell>
                        <TableCell>Result tier</TableCell>
                        <TableCell>Result name</TableCell>
                        <TableCell></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {mappings.map((mapping, index) => {
                        return (
                           <TableRow>
                              <TableCell>{mapping.prizeTier.tournament.name}</TableCell>
                              <TableCell>{mapping.prizeTier.tier}</TableCell>
                              <TableCell>{mapping.prizeTier.name}</TableCell>
                              <TableCell>
                                 <CustomIconButton
                                    icon={<LinkOffIcon />}
                                    onClick={async () => {
                                       await remotePointsTier.unlinkPrizeTier(
                                          mapping.prizeTier.tournament.id,
                                          mapping.prizeTier.tier
                                       );
                                       showMessage("Tournament result link removed!", "success");
                                    }}
                                 >
                                    Unlink
                                 </CustomIconButton>
                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
               {mappings.length === 0 && (
                  <Alert severity={"info"}>No linked tournament results found for this tier.</Alert>
               )}
            </TableContainer>
         </DialogContent>
         <DialogActions>
            <Button onClick={close} autoFocus>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
