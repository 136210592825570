import React, { useEffect, useMemo, useState } from "react";
import { Alert, Box, Button, Paper, TablePagination, TextField, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useCurrentUser from "../../auth/useCurrentUser";
import { useSeriesApi } from "../../rest.client/useSeriesApi";
import { Maybe } from "../../TYPE";
import SeriesTile from "./SeriesTile";
import TablePaginationActions from "../../gameFeatures/myGamesPage/TablePagingActions";
import CreateOrEditSeriesDialog from "../CreateOrEditSeriesDialog";

interface ITournamentsViewPage {}

const ListSeriesPage: React.FC<ITournamentsViewPage> = () => {
   const theme = useTheme();
   const currentUser = useCurrentUser();
   const {
      findSeriesByText: { call: searchSeries, responseData, loading },
   } = useSeriesApi();
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const allSeries = responseData?._embedded.series || [];
   const pageData = responseData?.page;
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(25);
   const [searchText, setSearchText] = useState<Maybe<string>>(null);
   const [openCreateSeriesDialog, setOpenCreateSeriesDialog] = React.useState<boolean>(false);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      searchSeries({
         queryParams: {
            page: page,
            size: rowsPerPage,
            searchText: searchText,
            sort: "createDate,desc",
         },
      });
   }, [currentUser.user, page, rowsPerPage, searchText, searchSeries]);

   const tournamentTiles = useMemo(() => {
      if (loading) return <CircularProgress size={80} />;
      if (allSeries.length > 0) {
         return allSeries.map((seriesSummary) => <SeriesTile key={seriesSummary.id} series={seriesSummary} />);
      } else {
         return (
            <Paper
               elevation={4}
               sx={{
                  padding: theme.spacing(4),
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
               }}
            >
               <Typography variant={"h6"}>{"No series match your filters!"}</Typography>
            </Paper>
         );
      }
   }, [allSeries, loading, theme]);

   return (
      <Box
         id={"tournamentsViewPageRoot"}
         style={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexWrap: "wrap",
            boxSizing: "border-box",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            alignContent: "flex-start",
            rowGap: "5%",
         }}
      >
         <Paper sx={{ width: "100%" }}>
            <Alert severity={"info"}>
               Tournament Series is still a work in progress 🏗. We will do our best to keep disruption to a minimum but
               please let us know if you find bugs!
            </Alert>
            <Box
               sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(1),
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                  padding: theme.spacing(1),
               }}
            >
               <TextField
                  size={"small"}
                  variant={"outlined"}
                  defaultValue={""}
                  label={"Search"}
                  sx={{ minWidth: "200px", flexGrow: 1 }}
                  onChange={(ev) => {
                     if (searchActiveTimeout != null) {
                        clearTimeout(searchActiveTimeout);
                        setSearchActiveTimeout(null);
                     }
                     const timeOutId = window.setTimeout(() => {
                        setSearchText(ev.target.value);
                     }, 500);
                     setSearchActiveTimeout(timeOutId);
                  }}
               />
               <Button variant={"contained"} onClick={() => setOpenCreateSeriesDialog(true)}>
                  Create series
               </Button>
               {pageData && (
                  <Box>
                     <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={pageData.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                           inputProps: {
                              "aria-label": "rows per page",
                           },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                     />
                  </Box>
               )}
            </Box>
         </Paper>
         <Box
            sx={{
               display: "flex",
               flexWrap: "wrap",
               alignItems: "flex-start",
               justifyContent: "space-evenly",
               gap: theme.spacing(4),
               width: "100%",
            }}
         >
            {tournamentTiles}
         </Box>
         {openCreateSeriesDialog && (
            <CreateOrEditSeriesDialog open={openCreateSeriesDialog} close={() => setOpenCreateSeriesDialog(false)} />
         )}
      </Box>
   );
};

export default ListSeriesPage;
