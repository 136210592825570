import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import MarkdownDescriptionDisplay from "../../pageTournament/MarkdownDescriptionDisplay";
import { RemoteSeries } from "../useRemoteSeries";
import { RemotePromoter } from "../../promoters/useRemotePromoter";
import CreateOrEditSeriesDialog from "../CreateOrEditSeriesDialog";
import { useNavigate } from "react-router-dom";
import SeriesDetailsCard from "./SeriesDetailsCard";
import SeriesControls from "./SeriesControls";

const SeriesDetails: React.FC<{ remotePromoter: RemotePromoter; remoteSeries: RemoteSeries }> = ({
   remotePromoter,
   remoteSeries,
}) => {
   const seriesResponse = remoteSeries.fullSeriesData;
   const [openEditSeries, setOpenEditSeries] = useState(false);
   const theme = useTheme();
   const nav = useNavigate();
   return (
      <Box
         sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
         }}
      >
         <Box sx={{ width: "100%", gap: theme.spacing(1), display: "flex", flexDirection: "column" }}>
            <SeriesDetailsCard remoteSeries={remoteSeries} />
            <SeriesControls
               remoteSeries={remoteSeries}
               remotePromoter={remotePromoter}
               onClickEdit={() => setOpenEditSeries(true)}
            />
         </Box>
         <Box sx={{ display: "flex" }}>
            <MarkdownDescriptionDisplay description={remoteSeries.seriesData.description || ""} elevation={0} />
         </Box>
         {openEditSeries && (
            <CreateOrEditSeriesDialog
               open={openEditSeries}
               close={() => setOpenEditSeries(false)}
               seriesId={seriesResponse?.id}
            />
         )}
      </Box>
   );
};

export default SeriesDetails;
