import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { PromoterSummaryResponse } from "./usePromotersApi";
import { PageableQueryParams, PageableResponse, UserSummary } from "./useApplicationApi";
import { Maybe } from "../TYPE";
import { PrizeTierSummaryResponse, PrizeTierWithTournamentResponse } from "./useTournamentApi";

export function useSeriesApi() {
   const instance = useAxiosInstance();

   const api = {
      createSeries: useRequest<SeriesSummaryResponse, never, SaveSeriesRequest, never>(
         useMemo(
            () => ({
               url: "/series",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateSeries: useRequest<SeriesSummaryResponse, { id: string }, SaveSeriesRequest, never>(
         useMemo(
            () => ({
               url: "/series/{id}/update",
               method: "post",
            }),
            []
         ),
         instance
      ),
      linkTournament: useRequest<SeriesSummaryResponse, { id: string }, LinkSeriesRequest, never>(
         useMemo(
            () => ({
               url: "/series/{id}/tournaments",
               method: "post",
            }),
            []
         ),
         instance
      ),
      unlinkTournament: useRequest<SeriesSummaryResponse, { id: string; tournamentId: string }, never, never>(
         useMemo(
            () => ({
               url: "/series/{id}/tournaments/{tournamentId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      findSeriesByText: useRequest<SeriesSummariesResponse, never, never, SeriesFindByTextQueryParams>(
         useMemo(
            () => ({
               url: "/series/search/byText/",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  size: 25,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      findSeriesByTextAndPromoterId: useRequest<
         SeriesSummariesResponse,
         never,
         never,
         SeriesFindByTextAndPromoterIdQueryParams
      >(
         useMemo(
            () => ({
               url: "/series/search/byTextAndPromoterId",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  size: 25,
                  page: 0,
                  sort: "",
                  promoterId: "",
               },
            }),
            []
         ),
         instance
      ),
      findSeriesByTextAndTournamentId: useRequest<
         SeriesSummariesResponse,
         never,
         never,
         SeriesFindByTextAndTournamentIdQueryParams
      >(
         useMemo(
            () => ({
               url: "/series/search/byTextAndTournamentId",
               method: "get",
               queryParamsDefault: {
                  projection: "summary",
                  size: 25,
                  page: 0,
                  sort: "",
                  tournamentId: "",
               },
            }),
            []
         ),
         instance
      ),
      getSeries: useRequest<SeriesFullResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/series/{id}?projection=full",
               method: "get",
            }),
            []
         ),
         instance
      ),
      getSeriesStandings: useRequest<SeriesStandingsListResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/series/{id}/standings",
               method: "get",
            }),
            []
         ),
         instance
      ),
      createSeriesPointsTier: useRequest<
         SeriesPointsTierSummaryResponse,
         {
            id: string;
         },
         SaveSeriesPointsTierRequest,
         never
      >(
         useMemo(
            () => ({
               url: "/series/{id}/seriesPointsTiers",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateSeriesPointsTier: useRequest<
         SeriesPointsTierSummaryResponse,
         {
            id: string;
            tierNumber: number;
         },
         SaveSeriesPointsTierRequest,
         never
      >(
         useMemo(
            () => ({
               url: "/series/{id}/seriesPointsTiers/{tierNumber}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      getSeriesPointsTierFull: useRequest<
         SeriesPointsTierFullResponse,
         {
            seriesId: string;
            tierNumber: number;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/seriesPointsTiers/{seriesId}_{tierNumber}?projection=full",
               method: "get",
            }),
            []
         ),
         instance
      ),
      linkSeriesPointsTier: useRequest<
         SeriesPointsTierMappingSummaryResponse,
         {
            id: string;
            tierNumber: number;
         },
         { tournamentId: string; prizeTier: number },
         never
      >(
         useMemo(
            () => ({
               url: "/series/{id}/seriesPointsTiers/{tierNumber}/prizeTiers",
               method: "post",
            }),
            []
         ),
         instance
      ),
      unlinkSeriesPointsTier: useRequest<
         never,
         {
            seriesId: string;
            tierNumber: number;
            tournamentId: string;
            prizeTier: number;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/series/{seriesId}/seriesPointsTiers/{tierNumber}/prizeTiers/{tournamentId}/{prizeTier}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      deleteSeriesPointsTier: useRequest<
         never,
         {
            id: string;
            tierNumber: number;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/series/{id}/seriesPointsTiers/{tierNumber}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      uploadLogo: useRequest<never, { id: string }, FormData, never>(
         useMemo(
            () => ({
               url: "/series/{id}/logo",
               method: "post",
               configMutator: (baseConfig) => {
                  if (!baseConfig.headers) {
                     baseConfig.headers = {};
                  }
                  baseConfig.headers["Content-Type"] = "multipart/form-data";
                  return baseConfig;
               },
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface SeriesStandingsListResponse {
   standings: SeriesStandingsResponse[];
}

export interface SeriesStandingsResponse {
   user: UserSummary;
   standingResults: SeriesStandingsResult;
}

export interface SeriesPointsTierSummaryResponse {
   name: string;
   seriesId: string;
   tier: number;
   points: number;
}

export interface SeriesPointsTierFullResponse extends SeriesPointsTierSummaryResponse {
   seriesPointsTierMappings: SeriesPointsTierMappingFullResponse[];
}

export interface SaveSeriesPointsTierRequest {
   name: string;
   points: number;
}

export interface SeriesPointsTierMappingSummaryResponse {
   seriesPointsTier: SeriesPointsTierSummaryResponse;
   prizeTier: PrizeTierSummaryResponse;
}

export interface SeriesPointsTierMappingFullResponse {
   seriesPointsTier: SeriesPointsTierSummaryResponse;
   prizeTier: PrizeTierWithTournamentResponse;
}

export interface SeriesStandingsResult {
   points: number;
   matchWins: number;
   byes: number;
   matchLosses: number;
   matchDraws: number;
   participationCount: number;
}

export interface SaveSeriesRequest {
   description: Maybe<string>;
   autoLinkPointsTiers: boolean;
   name: string;
   promoterId: string;
   matchWinPoints: number;
   matchDrawPoints: number;
   matchLossPoints: number;
   participationPoints: number;
   byePoints: number;
}

export type SeriesStatus = "ACTIVE" | "CLOSED";

export interface SeriesSummaryResponse {
   id: string;
   name: string;
   createDate: Date;
   status: SeriesStatus;
   autoLinkPointsTiers: boolean;
   description: string;
   promoter: PromoterSummaryResponse;
   logoUrl: Maybe<string>;
}

export interface LinkSeriesRequest {
   tournamentId: string;
}

export interface SeriesSummariesResponse extends PageableResponse<{ series: SeriesSummaryResponse[] }> {}

export interface SeriesFullResponse extends SeriesSummaryResponse {
   matchWinPoints: number;
   matchDrawPoints: number;
   matchLossPoints: number;
   participationPoints: number;
   byePoints: number;
   isAdmin: boolean;
   seriesPointsTiers: SeriesPointsTierSummaryResponse[];
}

export interface SeriesFindByTextQueryParams extends PageableQueryParams {
   searchText?: Maybe<string>;
}

export interface SeriesFindByTextAndPromoterIdQueryParams extends PageableQueryParams {
   searchText?: Maybe<string>;
   promoterId: string;
   projection: Maybe<string>;
}

export interface SeriesFindByTextAndTournamentIdQueryParams extends PageableQueryParams {
   searchText?: Maybe<string>;
   tournamentId: string;
   projection: Maybe<string>;
}
