import React, { FC } from "react";
import {
   Avatar,
   Box,
   Button,
   Card,
   CardHeader,
   CardMedia,
   IconButton,
   List,
   Paper,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toPromoter, toSeries } from "../../navPaths";
import { SxProps } from "@mui/system";
import { SeriesSummaryResponse } from "../../rest.client/useSeriesApi";

const rootSx: SxProps = {
   cursor: "pointer",
   width: { xs: `90%`, sm: `400px` },
   borderRadius: "5px",
   padding: "10px",
   boxSizing: "border-box",
   boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
   backdropFilter: "blur(15px) sepia(1)",
   "&:hover": {
      backdropFilter: "blur(30px) sepia(0)",
      boxShadow: `0 0 1vmin 0.3vmin rgba(0,0,0,1)`,
   },
   background: "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.5), rgba(0,0,0,0.8))",
};

const SeriesTile: FC<{ series: SeriesSummaryResponse }> = ({ series }) => {
   const nav = useNavigate();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   return (
      <Card
         onClick={() => nav(toSeries(series.id))}
         raised={true}
         elevation={2}
         sx={{
            cursor: "pointer",
            width: { xs: "350px", sm: "500px" },
            boxShadow: `0 0 0.8vmin 0.1vmin rgba(0,0,0,0.8)`,
         }}
      >
         <Paper elevation={3}>
            <CardMedia
               component="img"
               height={smallScreen ? "200px" : "300px"}
               image={series?.logoUrl != null ? series.logoUrl : "/centered_small_logo.png"}
               alt={series.name + " logo"}
            />
            <Box>
               {series.promoter?.logoUrl && (
                  <Box
                     sx={{
                        position: "relative",
                        top: "-80px",
                        left: "calc(100% - 100px)",
                        height: "0px",
                     }}
                     onClick={(e) => {
                        e.stopPropagation();
                        nav(toPromoter(series.promoter?.id || ""));
                     }}
                  >
                     <Avatar
                        sx={{ width: 96, height: 96 }}
                        alt={series.promoter.name + " logo"}
                        src={series.promoter.logoUrl}
                     />
                  </Box>
               )}
            </Box>
         </Paper>
         <CardHeader
            action={<IconButton aria-label="settings"></IconButton>}
            title={
               <Typography
                  variant={"h6"}
                  width={smallScreen ? "320px" : "440px"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  fontWeight={"bold"}
               >
                  {series.name}
               </Typography>
            }
            subheader={
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     {series?.promoter.logoUrl && (
                        <Avatar
                           sx={{ width: 32, height: 32 }}
                           alt={series?.promoter.name + " logo"}
                           src={series?.promoter.logoUrl}
                        />
                     )}
                     <Button
                        size={"small"}
                        variant={"text"}
                        onClick={() => nav(toPromoter(series?.promoter?.id || ""))}
                     >
                        {series.promoter?.name}
                     </Button>
                  </Box>
               </Box>
            }
         />
         <Box>
            <List dense={true} sx={{ display: "flex", justifyContent: "space-between" }}></List>
         </Box>
      </Card>
   );
};

export default SeriesTile;
