import React, { useCallback, useEffect, useMemo, useState } from "react";
import Panel from "./Panel";
import {
   Alert,
   Box,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormLabel,
   Radio,
   RadioGroup,
   TextField,
} from "@mui/material";
import { GLOBAL_VAR_NAME, TournamentCreationPagePanelIndex } from "../../UI_CONST";
import useGlobal from "../../global/useGlobal";
import { TTournamentsPageState } from "../../TYPE";
import { RegistrationMode, SaveTournamentRequest } from "../../rest.client/useTournamentApi";
import { useParams } from "react-router-dom";
import { tournamentsPageDefaultState } from "./TournamentCustomFlowDialog";
import { RemoteTournament } from "../useRemoteTournament";
import OrganiserEditBox from "./OrganiserEditBox";
import { Consumer } from "../../utils";
import { UserSummary } from "../../rest.client/useApplicationApi";
import HelpIcon from "../../common/HelpIcon";

const SettingsPanel: React.FC<{
   remoteTournament: RemoteTournament;
   addOrganiser: Consumer<UserSummary>;
   removeOrganiser: Consumer<UserSummary>;
   organisers: UserSummary[];
}> = ({ remoteTournament, addOrganiser, removeOrganiser, organisers }) => {
   const [tournamentsPageState, setTournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );
   const { tournamentId } = useParams();

   const [maxNumOfPlayersFieldError, setMaxNumOfPlayersFieldError] = useState<string | null>(null);

   const isCreatingNewTournament = remoteTournament.id == null;
   const isEditingExistingTournament = useMemo(
      () => Boolean(!isCreatingNewTournament && tournamentId),
      [isCreatingNewTournament, tournamentId]
   );
   const isPublic = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.isPublic
      : tournamentsPageState.createTournamentRequestData.isPublic;
   const makeDecklistsVisible = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.makeDecklistsVisible
      : tournamentsPageState.createTournamentRequestData.makeDecklistsVisible;
   const autoAccept = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.autoAcceptRegistrations
      : tournamentsPageState.createTournamentRequestData.autoAcceptRegistrations;
   const regMode = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.registrationMode
      : tournamentsPageState.createTournamentRequestData.registrationMode;
   const sendRegistrationEmails = isEditingExistingTournament
      ? tournamentsPageState.editTournamentRequestData.sendRegistrationEmailNotifications
      : tournamentsPageState.createTournamentRequestData.sendRegistrationEmailNotifications;

   const setJoinMethod = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const mode = event.target.value as RegistrationMode;
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 registrationMode: mode,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 registrationMode: mode,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const setMaxNumOfPlayers = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const num = Number(event.target.value);
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 maxPlayerCount: num,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 maxPlayerCount: num,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   const setPassword = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
         const password = event.target.value;
         const newData: SaveTournamentRequest = isEditingExistingTournament
            ? {
                 ...tournamentsPageState.editTournamentRequestData,
                 password: password,
              }
            : {
                 ...tournamentsPageState.createTournamentRequestData,
                 password: password,
              };
         isEditingExistingTournament
            ? setTournamentsPageState({ ...tournamentsPageState, editTournamentRequestData: newData })
            : setTournamentsPageState({ ...tournamentsPageState, createTournamentRequestData: newData });
      },
      [isEditingExistingTournament, setTournamentsPageState, tournamentsPageState]
   );

   useEffect(() => {
      const num = isEditingExistingTournament
         ? tournamentsPageState.editTournamentRequestData.maxPlayerCount
         : tournamentsPageState.createTournamentRequestData.maxPlayerCount;
      setMaxNumOfPlayersFieldError(num < 3 ? "Minimum 3 players" : num > 256 ? "Maximum 256 players" : null);
   }, [
      isEditingExistingTournament,
      tournamentsPageState.createTournamentRequestData.maxPlayerCount,
      tournamentsPageState.editTournamentRequestData.maxPlayerCount,
   ]);

   return (
      <Panel panelIndex={TournamentCreationPagePanelIndex.TOURNAMENT_SETTINGS} panelTitle={"Tournament Settings"}>
         <TextField
            fullWidth
            required
            error={maxNumOfPlayersFieldError !== null}
            value={
               isEditingExistingTournament
                  ? tournamentsPageState.editTournamentRequestData.maxPlayerCount
                  : tournamentsPageState.createTournamentRequestData.maxPlayerCount
            }
            type={"number"}
            size={"small"}
            variant={"outlined"}
            label={maxNumOfPlayersFieldError ? maxNumOfPlayersFieldError : "Max number of players"}
            onChange={setMaxNumOfPlayers}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 3, max: 256 } }}
         />
         <FormControl fullWidth>
            <FormLabel>{"Registration mode"}</FormLabel>
            <RadioGroup row={false} value={regMode} onChange={setJoinMethod}>
               <FormControlLabel value={"OPEN"} control={<Radio size={"small"} />} label="Open registration" />
               <FormControlLabel value={"INVITE"} control={<Radio size={"small"} />} label="Invites only" />
               <FormControlLabel value={"PASSWORD"} control={<Radio size={"small"} />} label="Password" />
            </RadioGroup>
         </FormControl>
         {regMode === "PASSWORD" && (
            <TextField
               fullWidth
               required
               error={maxNumOfPlayersFieldError !== null}
               defaultValue={""}
               type={"password"}
               size={"small"}
               variant={"outlined"}
               label={"Enter password"}
               onChange={setPassword}
               InputLabelProps={{ shrink: true }}
               InputProps={{ inputProps: { min: 3, max: 256 } }}
            />
         )}
         <FormControl fullWidth>
            <FormLabel>{"Data visibility"}</FormLabel>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={isPublic}
                        onClick={() => {
                           setTournamentsPageState({
                              ...tournamentsPageState,
                              createTournamentRequestData: {
                                 ...tournamentsPageState.createTournamentRequestData,
                                 isPublic: !isPublic,
                              },
                              editTournamentRequestData: {
                                 ...tournamentsPageState.editTournamentRequestData,
                                 isPublic: !isPublic,
                              },
                           });
                        }}
                     />
                  }
                  label="Publish tournament data"
               />
               <HelpIcon
                  message={
                     "When this is checked all users will be able to see the tournament structure and data from the tournament will be used in " +
                     "future insights features. Certain data will always remain private such as rules infractions."
                  }
               />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={makeDecklistsVisible}
                        onClick={() => {
                           setTournamentsPageState({
                              ...tournamentsPageState,
                              createTournamentRequestData: {
                                 ...tournamentsPageState.createTournamentRequestData,
                                 makeDecklistsVisible: !makeDecklistsVisible,
                              },
                              editTournamentRequestData: {
                                 ...tournamentsPageState.editTournamentRequestData,
                                 makeDecklistsVisible: !makeDecklistsVisible,
                              },
                           });
                        }}
                     />
                  }
                  label="Decklists visible for all players"
               />
               <HelpIcon
                  message={
                     "When this is checked all decks submitted will be visible to all players. This option also allows all decklists to" +
                     " be visible in future deck search features."
                  }
               />
            </Box>
         </FormControl>
         <FormControl fullWidth>
            <FormLabel>{"Auto settings"}</FormLabel>
            {autoAccept && regMode === "OPEN" && (
               <Alert severity={"warning"}>
                  Auto accept is not recommended when using open registration mode. Please consider using a password or
                  switching to invite only.
               </Alert>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={autoAccept}
                        onClick={() => {
                           setTournamentsPageState({
                              ...tournamentsPageState,
                              createTournamentRequestData: {
                                 ...tournamentsPageState.createTournamentRequestData,
                                 autoAcceptRegistrations: !autoAccept,
                              },
                              editTournamentRequestData: {
                                 ...tournamentsPageState.editTournamentRequestData,
                                 autoAcceptRegistrations: !autoAccept,
                              },
                           });
                        }}
                     />
                  }
                  label="Auto accept all registrations"
               />
               <HelpIcon
                  message={
                     "When this is enabled players who register for a tournament will automatically be marked as accepted. " +
                     "This can reduce the amount of work for organisers typically used for password protected Leagues."
                  }
               />
            </Box>
         </FormControl>
         <FormControl fullWidth>
            <FormLabel>{"Email notifications"}</FormLabel>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={sendRegistrationEmails}
                        onClick={() => {
                           setTournamentsPageState({
                              ...tournamentsPageState,
                              createTournamentRequestData: {
                                 ...tournamentsPageState.createTournamentRequestData,
                                 sendRegistrationEmailNotifications: !sendRegistrationEmails,
                              },
                              editTournamentRequestData: {
                                 ...tournamentsPageState.editTournamentRequestData,
                                 sendRegistrationEmailNotifications: !sendRegistrationEmails,
                              },
                           });
                        }}
                     />
                  }
                  label="Send email to organisers on registration"
               />
               <HelpIcon
                  message={
                     "When this is enables organisers will receive an email whenever a user registers for the tournament."
                  }
               />
            </Box>
         </FormControl>
         <OrganiserEditBox addOrganiser={addOrganiser} removeOrganiser={removeOrganiser} organisers={organisers} />
      </Panel>
   );
};

export default SettingsPanel;
