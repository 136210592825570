import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import useGlobal from "../../global/useGlobal";
import { TTournamentsPageState } from "../../TYPE";
import {
   GLOBAL_VAR_NAME,
   NUM_OF_TOURNAMENT_CREATION_PAGE_PANELS,
   TournamentCreationPagePanelIndex,
} from "../../UI_CONST";
import { LARGE_PADDING_IN_PX, XLARGE_PADDING_IN_PX } from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import InfoPanel from "./InfoPanel";
import { SxProps } from "@mui/system";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsPanel from "./SettingsPanel";
import ConfirmationPanel from "./ConfirmationPanel";
import {
   EMPTY_SAVE_TOURNAMENT_REQUEST_DATA,
   SaveTournamentRequest,
   useTournamentApi,
} from "../../rest.client/useTournamentApi";
import { RemoteTournament } from "../useRemoteTournament";
import { Procedure } from "../../utils";
import { UserSummary } from "../../rest.client/useApplicationApi";
import NavigationBar from "./NavigationBar";

export const tournamentsPageDefaultState: TTournamentsPageState = {
   creationPageCurrentPanelIndex: TournamentCreationPagePanelIndex.TOURNAMENT_INFO,
   createTournamentRequestData: EMPTY_SAVE_TOURNAMENT_REQUEST_DATA,
   editTournamentRequestData: EMPTY_SAVE_TOURNAMENT_REQUEST_DATA,
   editTournamentId: null,
};

const tournamentsCreationDialogRootSx: SxProps = {
   boxSizing: "border-box",
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   "@media (max-width:600px)": {
      justifyContent: "flex-start",
   },
};

const panelsContainerSx: SxProps = {
   flexGrow: 1,
   maxHeight: "720px",
   left: 0,
   display: "flex",
   flexDirection: "column",
   boxSizing: "border-box",
   gap: `${LARGE_PADDING_IN_PX}px`,
   paddingTop: `${XLARGE_PADDING_IN_PX}px`,
   paddingBottom: `${XLARGE_PADDING_IN_PX}px`,
   alignItems: "center",
   "@media (max-width:600px)": {
      maxHeight: "none",
   },
};

const TournamentCustomFlowDialog: FC<{ open: boolean; remoteTournament: RemoteTournament; onClose: Procedure }> = ({
   open,
   onClose,
   remoteTournament,
}) => {
   const tournamentId = remoteTournament.id;
   const {
      getTournamentFullById: { call: getTournamentFullById, responseData: tournamentData },
   } = useTournamentApi();
   const [tournamentsPageState, setTournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );
   const [, setSearchTournaments] = useState<boolean>(false);
   const [organisers, setOrganisers] = useState<UserSummary[]>([]);
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const isCreatingNewTournament = tournamentId == null;

   const handleLeftArrowButtonClick = useCallback(() => {
      const newIndex = tournamentsPageState.creationPageCurrentPanelIndex - 1;
      if (0 <= newIndex && newIndex < NUM_OF_TOURNAMENT_CREATION_PAGE_PANELS) {
         setTournamentsPageState({
            ...tournamentsPageState,
            creationPageCurrentPanelIndex: newIndex,
         });
      }
   }, [setTournamentsPageState, tournamentsPageState]);

   const handleRightArrowButtonClick = useCallback(() => {
      const newIndex = tournamentsPageState.creationPageCurrentPanelIndex + 1;
      if (0 <= newIndex && newIndex < NUM_OF_TOURNAMENT_CREATION_PAGE_PANELS) {
         setTournamentsPageState({
            ...tournamentsPageState,
            creationPageCurrentPanelIndex: newIndex,
         });
      }
   }, [setTournamentsPageState, tournamentsPageState]);

   const fullClose = useCallback(() => {
      onClose();
      setTournamentsPageState(tournamentsPageDefaultState);
   }, [onClose, setTournamentsPageState]);

   useEffect(() => {
      const lengthDiff =
         tournamentsPageState.editTournamentRequestData.tournamentOrganisers.length !== organisers.length ||
         tournamentsPageState.createTournamentRequestData.tournamentOrganisers.length !== organisers.length;
      if (
         lengthDiff ||
         organisers.some(
            (o) => !tournamentsPageState.editTournamentRequestData.tournamentOrganisers.some((oId) => oId === o.id)
         ) ||
         organisers.some(
            (o) => !tournamentsPageState.createTournamentRequestData.tournamentOrganisers.some((oId) => oId === o.id)
         )
      ) {
         setTournamentsPageState({
            ...tournamentsPageState,
            editTournamentRequestData: {
               ...tournamentsPageState.editTournamentRequestData,
               tournamentOrganisers: organisers.map((o) => o.id),
            },
            createTournamentRequestData: {
               ...tournamentsPageState.createTournamentRequestData,
               tournamentOrganisers: organisers.map((o) => o.id),
            },
         });
      }
   }, [organisers, setTournamentsPageState, tournamentsPageState]);

   useEffect(() => {
      if (tournamentId) {
         setSearchTournaments(true);
         getTournamentFullById({ pathParams: { id: tournamentId } }).finally(() => setSearchTournaments(false));
      }
   }, [getTournamentFullById, tournamentId]);

   useEffect(() => {
      if (isCreatingNewTournament && tournamentsPageState.editTournamentId) {
         setTournamentsPageState({
            ...tournamentsPageState,
            createTournamentRequestData: EMPTY_SAVE_TOURNAMENT_REQUEST_DATA,
            editTournamentRequestData: EMPTY_SAVE_TOURNAMENT_REQUEST_DATA,
            editTournamentId: null,
         });
      } else if (tournamentId && tournamentData && tournamentsPageState.editTournamentId !== tournamentId) {
         setOrganisers(tournamentData.tournamentOrganisers);
         const loadedTournamentRequestData: SaveTournamentRequest = {
            description: tournamentData.description,
            autoAcceptRegistrations: tournamentData.autoAcceptRegistrations,
            promoterId: tournamentData.promoter?.id,
            startDate: new Date(tournamentData.startDate),
            endDate:
               tournamentData.endDate != null ? new Date(tournamentData.endDate) : new Date(tournamentData.startDate),
            maxPlayerCount: tournamentData.maxPlayerCount,
            makeDecklistsVisible: tournamentData.areDecklistsVisible,
            sendRegistrationEmailNotifications: tournamentData.sendRegistrationEmailNotifications,
            registrationMode: tournamentData.registrationMode,
            isPublic: tournamentData.isPublic,
            location: tournamentData.location,
            name: tournamentData.name,
            tournamentOrganisers: tournamentData.tournamentOrganisers.map((o) => o.id),
         };
         setTournamentsPageState({
            ...tournamentsPageState,
            createTournamentRequestData: EMPTY_SAVE_TOURNAMENT_REQUEST_DATA,
            editTournamentRequestData: loadedTournamentRequestData,
            editTournamentId: tournamentId,
         });
      }
   }, [isCreatingNewTournament, setTournamentsPageState, tournamentId, tournamentData, tournamentsPageState]);

   return (
      <Dialog open={open} fullScreen={smallScreen} maxWidth={"lg"} fullWidth={true}>
         <DialogTitle>
            {remoteTournament.id == null ? "Create custom tournament" : `Edit - ${remoteTournament.tournament?.name}`}
            <NavigationBar onClose={fullClose} />
         </DialogTitle>
         <DialogContent>
            <Box id={"tournamentsCreationPageRoot"} sx={tournamentsCreationDialogRootSx}>
               <Box id={"tournamentsCreationPagePanelsContainer"} sx={panelsContainerSx}>
                  <InfoPanel remoteTournament={remoteTournament} />
                  <SettingsPanel
                     remoteTournament={remoteTournament}
                     organisers={organisers}
                     addOrganiser={(o) => setOrganisers([...organisers, o])}
                     removeOrganiser={(o) => setOrganisers(organisers.filter((eO) => eO.id !== o.id))}
                  />
                  <ConfirmationPanel remoteTournament={remoteTournament} onClose={onClose} />
               </Box>
            </Box>
         </DialogContent>
         <DialogActions>
            {!smallScreen && (
               <Button
                  disabled={tournamentsPageState.creationPageCurrentPanelIndex <= 0}
                  onClick={handleLeftArrowButtonClick}
               >
                  <KeyboardArrowLeftIcon fontSize={"medium"} />
                  Back
               </Button>
            )}
            {!smallScreen && (
               <Button
                  disabled={
                     tournamentsPageState.creationPageCurrentPanelIndex + 1 >= NUM_OF_TOURNAMENT_CREATION_PAGE_PANELS
                  }
                  onClick={handleRightArrowButtonClick}
               >
                  Next
                  <KeyboardArrowRightIcon fontSize={"medium"} />
               </Button>
            )}
         </DialogActions>
      </Dialog>
   );
};

export default TournamentCustomFlowDialog;
