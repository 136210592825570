import React, { useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackDropSpinner from "../../common/BackDropSpinner";
import { RemoteSeries } from "../useRemoteSeries";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import SeriesPointsTierRow from "./SeriesPointsTierRow";
import { Maybe } from "../../TYPE";
import { SeriesPointsTierSummaryResponse } from "../../rest.client/useSeriesApi";
import SeriesPointsTierLinkDialog from "./SeriesPointsTierLinkDialog";
import CustomIconButton from "../../common/CustomIconButton";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HelpIcon from "../../common/HelpIcon";

export default function SeriesPointsTierExpander({ remoteSeries }: { remoteSeries: RemoteSeries }) {
   const theme = useTheme();
   const displayMessage = useAddMessageItem();
   const [expanded, setExpanded] = useState(false);
   const seriesFull = remoteSeries.fullSeriesData;
   const loading = remoteSeries.loading;
   const pointsTiers = seriesFull?.seriesPointsTiers || [];
   const [tierName, setTierName] = useState("");
   const [points, setPoints] = useState<number>(0);
   const [tierToLink, setTierToLink] = useState<Maybe<SeriesPointsTierSummaryResponse>>(null);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <EmojiEventsIcon />
               <Typography>Points allocation tiers</Typography>
               <HelpIcon
                  message={
                     "Here you can allocate points to tournament results. Points are allocated in tiers with tier one typically being for a win, tier two for top 4, tier three for top 16 and so on."
                  }
               />
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            <BackDropSpinner show={loading} />
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
               <TextField
                  fullWidth
                  required
                  size={"small"}
                  variant={"outlined"}
                  label={"Tier name"}
                  onChange={(ev) => setTierName(ev.target.value)}
                  value={tierName}
                  inputProps={{ maxLength: 100 }}
               />
               <TextField
                  fullWidth
                  required
                  size={"small"}
                  variant={"outlined"}
                  type={"number"}
                  label={"Points"}
                  error={isNaN(points) || points < 0}
                  onChange={(ev) => {
                     setPoints(parseInt(ev.target.value));
                  }}
                  value={points}
                  inputProps={{ maxLength: 100 }}
               />
               <CustomIconButton
                  icon={<AddCircleIcon />}
                  buttonProps={{
                     disabled: tierName === "" || points == null || isNaN(points) || points < 0,
                     variant: "contained",
                  }}
                  sx={{ minWidth: 100 }}
                  onClick={async () => {
                     console.log(points);
                     await remoteSeries.createPointsTier({ name: tierName, points: points });
                     setTierName("");
                     setPoints(0);
                     displayMessage("Tier " + tierName + " created!", "success");
                  }}
               >
                  Create
               </CustomIconButton>
            </Box>
            <Paper sx={{ width: "100%", position: "relative" }}>
               <TableContainer component={Paper}>
                  <Table sx={{}} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Tier</TableCell>
                           <TableCell>Name</TableCell>
                           <TableCell>Points</TableCell>
                           <TableCell></TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {pointsTiers.map((tierResponse, index) => {
                           return (
                              <SeriesPointsTierRow
                                 remoteSeries={remoteSeries}
                                 tierResponse={tierResponse}
                                 showDelete={index === pointsTiers.length - 1}
                                 onClickLink={(tierToLink) => setTierToLink(tierToLink)}
                              />
                           );
                        })}
                     </TableBody>
                  </Table>
                  {pointsTiers.length === 0 && <Alert severity={"info"}>No points tiers found.</Alert>}
               </TableContainer>
               {tierToLink != null && (
                  <SeriesPointsTierLinkDialog
                     open={true}
                     close={() => setTierToLink(null)}
                     remoteSeries={remoteSeries}
                     seriesTier={tierToLink}
                  />
               )}
            </Paper>
         </AccordionDetails>
      </Accordion>
   );
}
