import React, { useEffect, useMemo, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   TablePagination,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RemotePromoter } from "../useRemotePromoter";
import { Maybe } from "../../TYPE";
import HelpIcon from "../../common/HelpIcon";
import { TbTournament } from "react-icons/tb";
import TablePaginationActions from "../../gameFeatures/myGamesPage/TablePagingActions";
import BackDropSpinner from "../../common/BackDropSpinner";
import { useSeriesApi } from "../../rest.client/useSeriesApi";
import SeriesTile from "../../series/listSeriesPage/SeriesTile";

export default function PromoterSeriesExpander({ remotePromoter }: { remotePromoter: RemotePromoter }) {
   const theme = useTheme();
   const response = remotePromoter.promoterFullResponse;
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [searchText, setSearchText] = useState<string>("");
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const promoterId = remotePromoter.promoterFullResponse?.id;
   const {
      findSeriesByTextAndPromoterId: { call: findSeries, responseData: findResponse },
      loading,
   } = useSeriesApi();
   const series = useMemo(() => findResponse?._embedded.series || [], [findResponse?._embedded.series]);
   const pageData = findResponse?.page;

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      if (promoterId) {
         findSeries({
            queryParams: {
               promoterId: promoterId,
               size: rowsPerPage,
               page,
               searchText,
               sort: "createDate,desc",
               projection: "summary",
            },
         });
      }
   }, [findSeries, page, promoterId, rowsPerPage, searchText]);

   const tournamentTiles = useMemo(() => {
      if (series.length > 0) {
         return series.map((seriesSummary) => <SeriesTile key={seriesSummary.id} series={seriesSummary} />);
      } else {
         return (
            <Alert severity={"info"} sx={{ width: "100%" }}>
               {"No Series associated with this promoter match your search!"}
            </Alert>
         );
      }
   }, [series]);

   return (
      <Accordion defaultExpanded={true} sx={{ width: "100%" }}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
               }}
            >
               <TbTournament size={"24px"} />
               <Typography>Series</Typography>
               {response?.isAdmin && (
                  <HelpIcon message={"This lists all tournament series organised by this promoter."} />
               )}
            </Box>
         </AccordionSummary>
         <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
            <BackDropSpinner show={loading} />
            <Box
               sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
               }}
            >
               <TextField
                  size={"small"}
                  variant={"outlined"}
                  defaultValue={""}
                  label={"Search"}
                  sx={{ minWidth: "200px", flexGrow: 1 }}
                  onChange={(ev) => {
                     if (searchActiveTimeout != null) {
                        clearTimeout(searchActiveTimeout);
                        setSearchActiveTimeout(null);
                     }
                     const timeOutId = window.setTimeout(() => {
                        setSearchText(ev.target.value);
                     }, 500);
                     setSearchActiveTimeout(timeOutId);
                  }}
               />
               {pageData && (
                  <Box>
                     <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={pageData.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                           inputProps: {
                              "aria-label": "rows per page",
                           },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                     />
                  </Box>
               )}
            </Box>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                  flexWrap: "wrap",
               }}
            >
               {tournamentTiles}
            </Box>
         </AccordionDetails>
      </Accordion>
   );
}
