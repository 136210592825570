import React, { SyntheticEvent, useCallback, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { Maybe } from "../TYPE";
import { TournamentSummariesResponse, TournamentSummary } from "../rest.client/useTournamentApi";

const TournamentSelectorAutocomplete: React.FC<{
   tournamentSummary: Maybe<TournamentSummary>;
   selectionHandler: (summary: TournamentSummary) => void;
   searchHandler: (searchText: string) => Promise<TournamentSummariesResponse>;
   values: TournamentSummary[];
   sx?: SxProps;
}> = ({ selectionHandler, tournamentSummary, searchHandler, values, sx }) => {
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const [processingInput, setProcessingInput] = useState(false);

   const searchHandlerCall = useCallback(
      (value: string) => {
         if (searchActiveTimeout != null) {
            clearTimeout(searchActiveTimeout);
            setSearchActiveTimeout(null);
         }
         setProcessingInput(true);
         const timeOutId = window.setTimeout(() => {
            searchHandler(value).finally(() => {
               setProcessingInput(false);
            });
         }, 500);
         setSearchActiveTimeout(timeOutId);
      },
      [searchActiveTimeout, searchHandler]
   );

   const findAllLinkable = useCallback(() => {
      searchHandler("");
   }, [searchHandler]);

   const onChange = useCallback(
      (event: SyntheticEvent, val: Maybe<TournamentSummary>) => {
         val && selectionHandler(val);
      },
      [selectionHandler]
   );

   return (
      <Autocomplete
         forcePopupIcon={false}
         size={"small"}
         onInputChange={(ev, value) => searchHandlerCall(value)}
         onChange={onChange}
         fullWidth={true}
         onFocus={findAllLinkable}
         value={tournamentSummary || null}
         getOptionLabel={(option) => option.name}
         isOptionEqualToValue={(option, value) => option.id === value.id}
         loading={processingInput}
         noOptionsText={"No tournaments found."}
         clearOnBlur={true}
         renderInput={(params) => (
            <TextField
               sx={{}}
               label={"Search to link tournaments"}
               color={"primary"}
               {...params}
               InputProps={{
                  ...params.InputProps,
               }}
            />
         )}
         options={values}
      />
   );
};

export default TournamentSelectorAutocomplete;
