import React, { ReactNode } from "react";
import { Box, Button, Theme, useTheme } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { SxProps } from "@mui/system";

export default function CustomIconButton({
   icon,
   children,
   onClick,
   buttonProps,
   sx,
}: {
   icon?: ReactNode;
   children: ReactNode;
   buttonProps?: ButtonProps;
   sx?: SxProps<Theme>;
   onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
   const theme = useTheme();
   return (
      <Button
         sx={{
            justifyContent: "space-evenly",
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
            ...sx,
         }}
         onClick={onClick}
         {...buttonProps}
      >
         {icon}
         <Box>{children}</Box>
      </Button>
   );
}
