import React, { useEffect, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackDropSpinner from "../../common/BackDropSpinner";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { RemoteSeries } from "../useRemoteSeries";
import { useSeriesApi } from "../../rest.client/useSeriesApi";
import UserChip from "../../common/UserChip";

export default function SeriesStandingsTableExpander({ remoteSeries }: { remoteSeries: RemoteSeries }) {
   const {
      getSeriesStandings: { call: getSeriesStandingsCall, responseData: standingsData },
      loading,
   } = useSeriesApi();
   const theme = useTheme();
   const [expanded, setExpanded] = useState(false);
   const standings = standingsData?.standings || [];

   useEffect(() => {
      if (expanded && remoteSeries.fullSeriesData?.id && standingsData == null) {
         getSeriesStandingsCall({ pathParams: { id: remoteSeries.fullSeriesData?.id } });
      }
   }, [expanded, getSeriesStandingsCall, remoteSeries.fullSeriesData?.id, standingsData]);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <FormatListNumberedIcon />
               <Typography>Overall standings</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            <BackDropSpinner show={loading} />
            <Paper sx={{ width: "100%", position: "relative" }}>
               <TableContainer component={Paper}>
                  <Table sx={{}} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                           <TableCell>Points</TableCell>
                           <TableCell>Wins</TableCell>
                           <TableCell>Losses</TableCell>
                           <TableCell>Draws</TableCell>
                           <TableCell>Byes</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {standings.map((standing) => {
                           return (
                              <TableRow key={standing.user.id} sx={{ cursor: "pointer" }}>
                                 <TableCell sx={{ display: "flex", gap: theme.spacing(1), flexWrap: "wrap" }}>
                                    <UserChip user={standing.user} />
                                 </TableCell>
                                 <TableCell>{standing.standingResults.points}</TableCell>
                                 <TableCell>{standing.standingResults.matchWins}</TableCell>
                                 <TableCell>{standing.standingResults.matchLosses}</TableCell>
                                 <TableCell>{standing.standingResults.matchDraws}</TableCell>
                                 <TableCell>{standing.standingResults.byes}</TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
                  {standings.length === 0 && <Alert severity={"info"}>No standings data found.</Alert>}
               </TableContainer>
            </Paper>
         </AccordionDetails>
      </Accordion>
   );
}
