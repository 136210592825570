import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import BackDropSpinner from "../../common/BackDropSpinner";
import ScrollableBorderedWrapper from "../../common/ScrollableBorderedWrapper";
import { useParams } from "react-router-dom";
import useRemotePromoter from "../useRemotePromoter";
import PromoterDetails from "./PromoterDetails";
import useReload from "../../header/useReload";
import PromoterAdminTeamExpander from "./PromoterAdminTeamExpander";
import PromoterOrganiserTeamExpander from "./PromoterOrganiserTeamExpander";
import { PROMOTER } from "../../common/reloadKeys";
import { SxProps } from "@mui/system";
import PromoterTournamentsExpander from "./PromoterTournamentsExpander";
import PromoterSeriesExpander from "./PromoterSeriesExpander";

const contentRootSx: SxProps = {
   width: "100%",
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-start",
   alignItems: "center",
};

const PromoterViewPage: FC = () => {
   const { promoterId } = useParams();
   const remotePromoter = useRemotePromoter(promoterId);
   const { reload } = useReload(PROMOTER);

   useEffect(() => {
      if (remotePromoter.promoterFullResponse == null || reload) {
         remotePromoter.loadData();
      }
   }, [reload, remotePromoter]);

   return (
      <ScrollableBorderedWrapper>
         <BackDropSpinner show={remotePromoter.loading} />
         <Box sx={contentRootSx}>
            <PromoterDetails remotePromoter={remotePromoter} />
            {remotePromoter.promoterFullResponse?.isOrganiser && (
               <PromoterAdminTeamExpander remotePromoter={remotePromoter} />
            )}
            {remotePromoter.promoterFullResponse?.isOrganiser && (
               <PromoterOrganiserTeamExpander remotePromoter={remotePromoter} />
            )}
            <PromoterTournamentsExpander remotePromoter={remotePromoter} />
            <PromoterSeriesExpander remotePromoter={remotePromoter} />
         </Box>
      </ScrollableBorderedWrapper>
   );
};

export default PromoterViewPage;
