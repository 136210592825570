import React from "react";
import {
   Box,
   Card,
   CardContent,
   CardMedia,
   List,
   ListItem,
   ListItemText,
   Paper,
   Typography,
   useMediaQuery,
} from "@mui/material";
import { RemoteTournament } from "../useRemoteTournament";
import { getUserFriendlyNameForRegMode } from "../../rest.client/useTournamentApi";
import { useNavigate } from "react-router-dom";
import PromoterLinkAvatarButton from "../../promoters/listPromotersPage/PromoterLinkAvatarButton";

export default function TournamentDetailsCard({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const tournament = remoteTournament.tournament;
   const itemStyle = { width: "120px" };
   const nav = useNavigate();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   return (
      <>
         {tournament && (
            <Card>
               <Paper sx={{ position: "relative" }}>
                  <CardMedia
                     component="img"
                     height="300"
                     image={tournament?.logoUrl != null ? tournament.logoUrl : "/no_banner.png"}
                     alt={tournament?.name}
                  />
                  <Box sx={{ position: "absolute", bottom: 0, backgroundColor: "rgb(.6,.6,.6,.6)", width: "100%" }}>
                     <Typography variant={isMobileDevice ? "h6" : "h2"}>{tournament.name}</Typography>
                  </Box>
               </Paper>
               <CardContent sx={{ padding: "0px" }}>
                  <Paper elevation={2}>
                     <List sx={{ display: "flex", flexWrap: "wrap" }}>
                        {tournament.promoter && (
                           <ListItem sx={itemStyle}>
                              <ListItemText primary={<PromoterLinkAvatarButton promoter={tournament.promoter} />} />
                           </ListItem>
                        )}
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={new Date(tournament.startDate).toLocaleDateString()}
                              secondary={"Start date"}
                           />
                        </ListItem>
                        {tournament.endDate && (
                           <ListItem sx={itemStyle}>
                              <ListItemText
                                 primary={new Date(tournament.endDate).toLocaleDateString()}
                                 secondary={"End date"}
                              />
                           </ListItem>
                        )}
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={tournament.location} secondary={"Location"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={tournament.status} secondary={"Status"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={tournament.maxPlayerCount} secondary={"Max players"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={tournament.activeRegistrationCount} secondary={"Registered"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={getUserFriendlyNameForRegMode(tournament?.registrationMode)}
                              secondary={"Registration mode"}
                           />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText primary={tournament.isPublic ? "Yes" : "No"} secondary={"Data published"} />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={tournament.areDecklistsVisible ? "Yes" : "No"}
                              secondary={"Decks visible"}
                           />
                        </ListItem>
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={tournament.autoAcceptRegistrations ? "Yes" : "No"}
                              secondary={"Auto accept"}
                           />
                        </ListItem>
                     </List>
                  </Paper>
               </CardContent>
            </Card>
         )}
      </>
   );
}
