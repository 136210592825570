import React, { useEffect } from "react";
import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Link,
   TextField,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Consumer, Procedure } from "../utils";
import Spinner from "../common/Spinner";
import useRemotePromoter from "./useRemotePromoter";
import MarkdownDescriptionDisplay from "../pageTournament/MarkdownDescriptionDisplay";
import useAddMessageItem from "../messagesComponent/useAddMessageItem";
import useReload from "../header/useReload";
import { PROMOTER } from "../common/reloadKeys";
import { toPromoter } from "../navPaths";
import { PromoterSummaryResponse } from "../rest.client/usePromotersApi";

export default function CreateOrEditPromoterDialog({
   open,
   close,
   promoterId,
   navigateOnCreate = true,
   onCreate,
}: {
   open: boolean;
   close: Procedure;
   navigateOnCreate?: boolean;
   onCreate?: Consumer<PromoterSummaryResponse>;
   promoterId?: string;
}) {
   const theme = useTheme();
   const nav = useNavigate();
   const addMessageItem = useAddMessageItem();
   const { requestReload } = useReload(PROMOTER);
   const {
      loading,
      savePromoter,
      promoterFullResponse,
      dataInError,
      loadData,
      promoterData,
      setName,
      setDescription,
      setLocation,
   } = useRemotePromoter(promoterId);
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   useEffect(() => {
      if (promoterFullResponse == null && promoterId != null) {
         loadData();
      }
   }, [loadData, promoterFullResponse, promoterId]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         fullScreen={smallScreen}
         fullWidth={true}
         maxWidth={"lg"}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Create promoter account</DialogTitle>
         <DialogContent>
            <Spinner show={loading} />
            {!loading && (
               <Box
                  sx={{
                     margin: theme.spacing(1),
                     display: "flex",
                     flexDirection: "column",
                     gap: theme.spacing(1),
                  }}
               >
                  <TextField
                     sx={{}}
                     fullWidth
                     required
                     error={promoterData.name === ""}
                     variant={"outlined"}
                     label={"Name"}
                     onChange={(event) => setName(event.target.value)}
                     value={promoterData.name}
                     inputProps={{ maxLength: 100 }}
                  />
                  <TextField
                     sx={{}}
                     fullWidth
                     variant={"outlined"}
                     label={"Location"}
                     onChange={(event) => setLocation(event.target.value)}
                     value={promoterData.location}
                     inputProps={{ maxLength: 100 }}
                  />
                  <Alert sx={{ width: "100%" }} severity={"info"}>
                     Description supports Markdown learn more
                     <Link target={"_blank"} rel="noopener" href={"https://commonmark.org/help/"}>
                        {" "}
                        here
                     </Link>
                  </Alert>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: smallScreen ? "column" : "row",
                        gap: theme.spacing(1),
                     }}
                  >
                     <TextField
                        fullWidth
                        multiline
                        rows={15}
                        size={"small"}
                        variant={"outlined"}
                        label={"Description"}
                        onChange={(event) => setDescription(event.target.value)}
                        value={promoterData.description}
                        inputProps={{ maxLength: 2000 }}
                     />
                     <MarkdownDescriptionDisplay description={promoterData.description || ""} />
                  </Box>
               </Box>
            )}
         </DialogContent>
         <DialogActions>
            <Button
               onClick={async () => {
                  const promoter = await savePromoter();
                  navigateOnCreate && nav(toPromoter(promoter.id));
                  onCreate && onCreate(promoter);
                  close();
                  requestReload();
                  addMessageItem("Promoter account saved!", "success");
               }}
               disabled={loading || dataInError}
            >
               Save
            </Button>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
