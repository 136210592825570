import { useCallback, useMemo, useState } from "react";
import { Maybe } from "../TYPE";
import {
   SaveSeriesPointsTierRequest,
   SeriesFullResponse,
   SeriesSummaryResponse,
   useSeriesApi,
} from "../rest.client/useSeriesApi";
import { PromoterSummaryResponse } from "../rest.client/usePromotersApi";

export interface RemoteSeries {
   seriesData: {
      id: Maybe<string>;
      description: Maybe<string>;
      name: string;
      promoterId: Maybe<string>;
      matchWinPoints: number;
      matchDrawPoints: number;
      matchLossPoints: number;
      participationPoints: number;
      autoLinkPointsTiers: boolean;
      byePoints: number;
   };
   promoterSummary: Maybe<PromoterSummaryResponse>;
   setPromoterSummary: (promoterSummary: PromoterSummaryResponse) => void;
   fullSeriesData: Maybe<SeriesFullResponse>;
   dataInError: boolean;
   loadData: () => Promise<void>;
   setDescription: (description: Maybe<string>) => void;
   setName: (name: string) => void;
   setMatchWinPoints: (points: number) => void;
   setAutoLinkPointsTiers: (enable: boolean) => void;
   setMatchDrawPoints: (points: number) => void;
   linkTournament: (uid: string) => Promise<void>;
   unlinkTournament: (uid: string) => Promise<void>;
   setParticipationPoints: (points: number) => void;
   createPointsTier: (pointsTierData: SaveSeriesPointsTierRequest) => Promise<void>;
   deletePointsTier: (tier: number) => Promise<void>;
   setByePoints: (points: number) => void;
   setMatchLossPoints: (points: number) => void;
   saveSeries: () => Promise<SeriesSummaryResponse>;
   uploadLogo: (image: FormData) => Promise<unknown>;
   loading: boolean;
}

export default function useRemoteSeries(seriesId?: string): RemoteSeries {
   const {
      createSeries: { call: createSeriesCall },
      updateSeries: { call: updateSeriesCall },
      getSeries: { call: getFullSeriesCall, responseData: fullSeriesData },
      linkTournament: { call: linkTournamentCall },
      unlinkTournament: { call: unlinkTournamentCall },
      createSeriesPointsTier: { call: createPointsTierCall },
      deleteSeriesPointsTier: { call: deletePointsTierCall },
      uploadLogo: { call: uploadLogoCall },
      loading,
   } = useSeriesApi();
   const [name, setName] = useState<string>("");
   const [description, setDescription] = useState<Maybe<string>>("");
   const [matchWinPoints, setMatchWinPoints] = useState<number>(5);
   const [matchDrawPoints, setMatchDrawPoints] = useState<number>(1);
   const [matchLossPoints, setMatchLossPoints] = useState<number>(0);
   const [participationPoints, setParticipationPoints] = useState<number>(2);
   const [enableAutoLinkPointsTiers, setEnableAutoLinkPointsTiers] = useState(true);
   const [promoterSummary, setPromoterSummary] = useState<Maybe<PromoterSummaryResponse>>();
   const [byePoints, setByePoints] = useState<number>(2);

   const dataInError = name === "" || name == null || promoterSummary == null;

   const loadData = useCallback(async () => {
      if (seriesId) {
         const fullResponse = await getFullSeriesCall({ pathParams: { id: seriesId } });
         setName(fullResponse.name);
         setPromoterSummary(fullResponse.promoter);
         setDescription(fullResponse.description);
         setMatchDrawPoints(fullResponse.matchDrawPoints);
         setParticipationPoints(fullResponse.participationPoints);
         setByePoints(fullResponse.byePoints);
         setMatchWinPoints(fullResponse.matchWinPoints);
         setMatchLossPoints(fullResponse.matchLossPoints);
         setEnableAutoLinkPointsTiers(fullResponse.autoLinkPointsTiers);
      }
   }, [getFullSeriesCall, seriesId]);

   const linkTournament = useCallback(
      async (id: string) => {
         if (seriesId) {
            await linkTournamentCall({ pathParams: { id: seriesId }, body: { tournamentId: id } });
            await loadData();
         }
      },
      [linkTournamentCall, loadData, seriesId]
   );

   const unlinkTournament = useCallback(
      async (id: string) => {
         if (seriesId) {
            await unlinkTournamentCall({ pathParams: { id: seriesId, tournamentId: id } });
            await loadData();
         }
      },
      [loadData, seriesId, unlinkTournamentCall]
   );

   const createPointsTier = useCallback(
      async (tierData: SaveSeriesPointsTierRequest) => {
         if (seriesId) {
            await createPointsTierCall({ pathParams: { id: seriesId }, body: tierData });
            await loadData();
         }
      },
      [createPointsTierCall, loadData, seriesId]
   );

   const deletePointsTier = useCallback(
      async (tier: number) => {
         if (seriesId) {
            await deletePointsTierCall({ pathParams: { id: seriesId, tierNumber: tier } });
            await loadData();
         }
      },
      [deletePointsTierCall, loadData, seriesId]
   );

   const uploadLogo = useCallback(
      async (image: FormData) => {
         if (seriesId) {
            await uploadLogoCall({ pathParams: { id: seriesId }, body: image });
            await loadData();
         }
      },
      [loadData, seriesId, uploadLogoCall]
   );

   const saveSeries = useCallback(async () => {
      if (seriesId) {
         return await updateSeriesCall({
            pathParams: { id: seriesId },
            body: {
               name,
               description,
               autoLinkPointsTiers: enableAutoLinkPointsTiers,
               matchLossPoints,
               matchDrawPoints,
               byePoints,
               matchWinPoints,
               participationPoints,
               promoterId: promoterSummary?.id || "",
            },
         });
      } else {
         return await createSeriesCall({
            body: {
               name,
               description,
               participationPoints,
               autoLinkPointsTiers: enableAutoLinkPointsTiers,
               matchLossPoints,
               matchWinPoints,
               matchDrawPoints,
               byePoints,
               promoterId: promoterSummary?.id || "",
            },
         });
      }
   }, [
      byePoints,
      createSeriesCall,
      description,
      enableAutoLinkPointsTiers,
      matchDrawPoints,
      matchLossPoints,
      matchWinPoints,
      name,
      participationPoints,
      promoterSummary?.id,
      seriesId,
      updateSeriesCall,
   ]);

   return useMemo(
      () => ({
         seriesData: {
            id: fullSeriesData?.id,
            description: description,
            autoLinkPointsTiers: enableAutoLinkPointsTiers,
            name,
            matchDrawPoints,
            matchLossPoints,
            participationPoints,
            matchWinPoints,
            byePoints,
            promoterId: promoterSummary?.id,
         },
         promoterSummary,
         setPromoterSummary,
         unlinkTournament,
         linkTournament,
         uploadLogo,
         fullSeriesData,
         dataInError,
         loadData,
         saveSeries,
         setAutoLinkPointsTiers: setEnableAutoLinkPointsTiers,
         setDescription,
         setName,
         setByePoints,
         setMatchDrawPoints,
         deletePointsTier,
         setMatchLossPoints,
         createPointsTier,
         setMatchWinPoints,
         setParticipationPoints,
         loading,
      }),

      [
         byePoints,
         createPointsTier,
         dataInError,
         deletePointsTier,
         description,
         enableAutoLinkPointsTiers,
         fullSeriesData,
         linkTournament,
         loadData,
         loading,
         matchDrawPoints,
         matchLossPoints,
         matchWinPoints,
         name,
         participationPoints,
         promoterSummary,
         saveSeries,
         unlinkTournament,
      ]
   );
}
