import React, { useCallback, useEffect, useState } from "react";
import {
   Alert,
   Box,
   Button,
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   FormLabel,
   Link,
   TextField,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Procedure } from "../utils";
import Spinner from "../common/Spinner";
import MarkdownDescriptionDisplay from "../pageTournament/MarkdownDescriptionDisplay";
import useAddMessageItem from "../messagesComponent/useAddMessageItem";
import useReload from "../header/useReload";
import { SERIES_KEY } from "../common/reloadKeys";
import { toSeries } from "../navPaths";
import useRemoteSeries from "./useRemoteSeries";
import PromoterSelectorAutocomplete from "../promoters/PromoterSelectorAutocomplete";
import DynamicNumberField from "../pageTournament/viewPage/createDialog/DynamicNumberField";
import CreateOrEditPromoterDialog from "../promoters/CreateOrEditPromoterDialog";
import HelpIcon from "../common/HelpIcon";

export default function CreateOrEditSeriesDialog({
   open,
   close,
   seriesId,
}: {
   open: boolean;
   close: Procedure;
   seriesId?: string;
}) {
   const theme = useTheme();
   const nav = useNavigate();
   const addMessageItem = useAddMessageItem();
   const { requestReload } = useReload(SERIES_KEY);
   const {
      loading,
      saveSeries,
      fullSeriesData,
      promoterSummary,
      setPromoterSummary,
      dataInError,
      loadData,
      seriesData,
      setName,
      setDescription,
      setMatchWinPoints,
      setMatchLossPoints,
      setMatchDrawPoints,
      setByePoints,
      setParticipationPoints,
      setAutoLinkPointsTiers,
   } = useRemoteSeries(seriesId);
   const [fieldErrorMap, setFieldErrorMap] = useState<Record<string, boolean>>({});
   const [openCreatePromoterAccount, setOpenCreatePromoterAccount] = useState<boolean>(false);
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const setError = useCallback((key: string, error: boolean) => {
      setFieldErrorMap((map) => {
         const newMap = { ...map };
         newMap[key] = error;
         return newMap;
      });
   }, []);

   useEffect(() => {
      if (fullSeriesData == null && seriesId != null) {
         loadData();
      }
   }, [fullSeriesData, loadData, seriesId]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         fullScreen={smallScreen}
         fullWidth={true}
         maxWidth={"lg"}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Save series</DialogTitle>
         <DialogContent>
            <Spinner show={loading} />
            {!loading && (
               <Box
                  sx={{
                     margin: theme.spacing(1),
                     display: "flex",
                     flexDirection: "column",
                     gap: theme.spacing(1),
                  }}
               >
                  <TextField
                     sx={{}}
                     fullWidth
                     required
                     error={seriesData.name === ""}
                     variant={"outlined"}
                     label={"Name"}
                     onChange={(event) => setName(event.target.value)}
                     value={seriesData.name}
                     inputProps={{ maxLength: 100 }}
                  />
                  {seriesId == null && (
                     <PromoterSelectorAutocomplete
                        label={"Select promoter account"}
                        size={"medium"}
                        promoterSummary={promoterSummary || null}
                        required={true}
                        selectionHandler={(p) => setPromoterSummary(p)}
                        readOnly={false}
                        onClickQuickCreate={() => setOpenCreatePromoterAccount(true)}
                     />
                  )}
                  <DynamicNumberField
                     label={"Points for match win"}
                     errorMapKey={"winPoints"}
                     setError={setError}
                     onChange={(points) => {
                        if (points >= 0) {
                           setMatchWinPoints(points);
                        }
                     }}
                     value={seriesData.matchWinPoints || 0}
                  />
                  <DynamicNumberField
                     label={"Points for match draw"}
                     errorMapKey={"drawPoints"}
                     setError={setError}
                     onChange={(points) => {
                        if (points >= 0) {
                           setMatchDrawPoints(points);
                        }
                     }}
                     value={seriesData.matchDrawPoints || 0}
                  />
                  <DynamicNumberField
                     label={"Points for match loss (negative)"}
                     errorMapKey={"lossPoints"}
                     setError={setError}
                     isError={(n) => n > 0}
                     onChange={(points) => {
                        if (points <= 0) {
                           setMatchLossPoints(points);
                        }
                     }}
                     value={seriesData.matchLossPoints || 0}
                  />
                  <DynamicNumberField
                     label={"Points for participation"}
                     errorMapKey={"participationPoints"}
                     setError={setError}
                     onChange={(points) => {
                        if (points >= 0) {
                           setParticipationPoints(points);
                        }
                     }}
                     value={seriesData.participationPoints || 0}
                  />
                  <DynamicNumberField
                     label={"Points for bye"}
                     errorMapKey={"byePoints"}
                     setError={setError}
                     onChange={(points) => {
                        if (points >= 0) {
                           setByePoints(points);
                        }
                     }}
                     value={seriesData.byePoints || 0}
                  />
                  <FormControl fullWidth>
                     <FormLabel>{"Auto settings"}</FormLabel>
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={seriesData.autoLinkPointsTiers}
                                 onClick={() => setAutoLinkPointsTiers(!seriesData.autoLinkPointsTiers)}
                              />
                           }
                           label="Auto link points tiers to tournament results"
                        />
                        <HelpIcon
                           message={
                              "When this is enabled tournament results will automatically be linked to points tiers for the series." +
                              "For example if a player is marked as the winner in a tournament and the series has configured 50 points " +
                              "for the winner that player will automatically receive those points on the series."
                           }
                        />
                     </Box>
                  </FormControl>
                  <Box>
                     <Alert sx={{ width: "100%" }} severity={"info"}>
                        Description supports Markdown learn more
                        <Link target={"_blank"} rel="noopener" href={"https://commonmark.org/help/"}>
                           {" "}
                           here
                        </Link>
                     </Alert>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: smallScreen ? "column" : "row",
                           gap: theme.spacing(1),
                        }}
                     >
                        <TextField
                           fullWidth
                           multiline
                           rows={15}
                           size={"small"}
                           variant={"outlined"}
                           label={"Description"}
                           onChange={(event) => setDescription(event.target.value)}
                           value={seriesData.description}
                           inputProps={{ maxLength: 2000 }}
                        />
                        <MarkdownDescriptionDisplay description={seriesData.description || ""} />
                     </Box>
                  </Box>
                  {openCreatePromoterAccount && (
                     <CreateOrEditPromoterDialog
                        open={openCreatePromoterAccount}
                        navigateOnCreate={false}
                        onCreate={(p) => setPromoterSummary(p)}
                        close={() => setOpenCreatePromoterAccount(false)}
                     />
                  )}
               </Box>
            )}
         </DialogContent>
         <DialogActions>
            <Button
               variant={"contained"}
               onClick={async () => {
                  const series = await saveSeries();
                  nav(toSeries(series.id));
                  close();
                  requestReload();
                  addMessageItem("Promoter account saved!", "success");
               }}
               disabled={loading || dataInError || Object.values(fieldErrorMap).some((v) => v)}
            >
               Save
            </Button>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
